import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// Styles must use direct files imports
import 'swiper/swiper.css'; // core Swiper
import 'swiper/modules/navigation.css'; // Navigation module
import 'swiper/modules/pagination.css'; // Pagination module
import 'swiper/modules/autoplay.css'; // Autoplay module

const swiper = new Swiper('.swiper', {
    modules: [Navigation, Pagination, Autoplay],
    direction: 'horizontal',
    loop: true,
    effect: "fade",
    loopPreventsSliding: false,
    pagination: {
      el: '.swiper-pagination',
      hideOnClick: false,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
});

document.querySelectorAll("video").forEach(video => {
    video.addEventListener("play", () => swiper.autoplay.stop());
    video.addEventListener("ended", () => {
      swiper.autoplay.start();
      swiper.slideNext();
    });
  }
)

swiper.on('slideChangeTransitionEnd', function () {
  var video = document.querySelector(".swiper-slide-active > video");
  if(video!=null)
    video.play();
});

//TODO
/*const connection = navigator.connection;
if (connection) {
  if (connection.effectiveType === "cellular") {
    swiper.removeSlide(1);
  }
}*/